@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-light: #8abdff;
  --primary: #6d5dfc;
  --primary-dark: #5b0eeb;

  --brShadow: -6px 6px 15px rgba(0, 0, 0, 0.5);
  --tlShadow: 6px -6px 15px rgba(255, 255, 255, 0.8);

  --white: #ffffff;
  --greyLight-1: #e4ebf5;
  --greyLight-2: #c8d0e7;
  --greyLight-3: #bec8e4;
  --greyDark: #9baacf;

  --gray: #c0c0c0;
}

* {
  background-color: var(--greyLight-1);
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.text-color {
  color: var(--greyDark);
}

.hover-text-color:hover {
  color: var(--primary-dark);
}

::-webkit-scrollbar {
  display: "none";
  visibility: hidden;
}
  