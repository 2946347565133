$shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
  -0.2rem -0.2rem 0.5rem var(--white);

$inner-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
  inset -0.2rem -0.2rem 0.5rem var(--white);

.button {
  box-shadow: $shadow;
  //   transition: all 0.5s ease;

  &:active {
    box-shadow: $inner-shadow;
  }
  &:hover {
    box-shadow: $inner-shadow;
  }
}

.active {
  box-shadow: $inner-shadow;
  color: var(--primary-dark);
}

.text {
  text-shadow: $shadow;
}

.shadow {
  box-shadow: $shadow;

  &:hover {
    box-shadow: $inner-shadow;
  }
}

.outer-shadow {
  box-shadow: $shadow;
}
.inner-shadow {
  box-shadow: $inner-shadow;
}
