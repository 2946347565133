$shadow: 0.3rem 0.3rem 0.6rem var(--greyLight-2),
    -0.2rem -0.2rem 0.5rem var(--white);

$inner-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
    inset -0.2rem -0.2rem 0.5rem var(--white);

ul {
    --col-gap: 2rem;
    --row-gap: 2rem;
    --line-w: 0.25rem;
    display: grid;
    grid-template-columns: var(--line-w) 1fr;
    grid-auto-columns: max-content;
    column-gap: var(--col-gap);
    list-style: none;
    width: min(60rem, 90%);
    margin-inline: auto;
}

/* line */
ul::before {
    content: "";
    grid-column: 1;
    grid-row: 1 / span 20;
    // background: var(--primary-dark);
    box-shadow: $shadow;
    border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
ul li:not(:last-child) {
    margin-bottom: var(--row-gap);
}

/* card */
ul li {
    grid-column: 2;
    --inlineP: 1.5rem;
    margin-inline: var(--inlineP);
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
}

/* date */
ul li .date {
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);

    text-align: center;
    /* background-color: var(--accent-color); */

    font-size: 1.25rem;
    font-weight: 700;

    display: grid;
    place-content: center;
    position: relative;

    // border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
ul li .date::before {
    content: "";
    width: var(--inlineP);
    aspect-ratio: 1;
    // background: var(--greyLight-1);
    // background-image: linear-gradient(var(--greyLight-1) 100%, transparent);
    position: absolute;
    top: 100%;
    box-shadow: $shadow;
    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
}

/* circle */
ul li .date::after {
    content: "";
    position: absolute;
    width: 2rem;
    aspect-ratio: 1;
    background: var(--greyLight-1);
    /* border: 0.3rem solid var(--accent-color); */
    border-radius: 50%;
    top: 50%;
    box-shadow: $shadow;

    transform: translate(50%, -50%);
    right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

ul li .date::after:hover {
    box-shadow: $inner-shadow;

}

/* title descr */
ul li .title,
ul li .descr {
    background: var(--bgColor);
    position: relative;
    padding-inline: 1.5rem;
}

ul li .title {
    overflow: hidden;
    padding-block-start: 1.5rem;
    padding-block-end: 0.6rem;
    font-weight: 500;
}

ul li .descr {
    padding-block-end: 1.5rem;
    font-weight: 300;
}

/* shadows */
// ul li .title::before,
// ul li .descr::before {
//     content: "";
//     position: absolute;
//     width: 90%;
//     height: 0.5rem;
//     background: rgba(0, 0, 0, 0.5);
//     left: 50%;
//     border-radius: 50%;
//     filter: blur(4px);
//     transform: translate(-50%, 50%);
// }

// ul li .title::before {
//     // bottom: calc(100% + 0.125rem);
// }

ul li .descr::before {
    z-index: -1;
    bottom: 0.25rem;
}

@media (min-width: 40rem) {
    ul {
        grid-template-columns: 1fr var(--line-w) 1fr;
    }

    ul::before {
        grid-column: 2;
    }

    ul li:nth-child(odd) {
        grid-column: 1;
    }

    ul li:nth-child(even) {
        grid-column: 3;
    }

    /* start second card */
    ul li:nth-child(2) {
        grid-row: 2/4;
    }

    ul li:nth-child(odd) .date::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        left: 0;
    }

    ul li:nth-child(odd) .date::after {
        transform: translate(-50%, -50%);
        left: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }

    // ul li:nth-child(odd) .date {
    //     border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
    // }
}